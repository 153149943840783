import { lastSeenSchema } from './types';

export function parseLastSeen(value: unknown) {
  const result = lastSeenSchema.safeParse(value);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
