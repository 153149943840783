import { createTrackerForCategory } from '@gonfalon/analytics';
import { getMemberListPageSize, isCustomRolesEnabled, isExpandMembersEndpointEnabled } from '@gonfalon/dogfood-flags';
import { BulkMemberActionNotification } from '@gonfalon/members';
import { formattedRoleName, RoleName } from '@gonfalon/permissions';
import { pluralize, wasOrWere } from '@gonfalon/strings';
import { subMonths } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Record } from 'immutable';
import qs from 'qs';

import { MemberTeams } from './accountUtils';
import { AnalyticAttributes } from './analyticsUtils';
import { Role } from './roleUtils';

type MemberFiltersProps = {
  limit: number;
  offset: number;
  query: string;
  roles: List<string>;
  lastSeen: string;
  sort: string;
  team: string;
  accessCheck?: string;
};

export type queryMapProps = Partial<MemberFiltersProps> & {
  q?: string;
};

export class MemberFilters extends Record<MemberFiltersProps>({
  limit: -1,
  offset: 0,
  query: '',
  roles: List(),
  lastSeen: '',
  sort: 'displayName',
  team: '',
  accessCheck: '',
}) {
  /**
   * Returns query string for UI for member list, for example: "?limit=20&offset=40&query=abc&roles=reader&roles=writer.
   * We deliberately avoid reserved characters that need to be encoded to keep the visible url pretty.
   */
  toFrontendMembersQueryString() {
    const q: {
      limit?: number;
      offset?: number;
      q?: string;
      roles?: string[];
      sort?: string;
      lastSeen?: string;
      team?: string;
    } = {};
    if (this.limit > 0) {
      q.limit = this.limit;
    }
    if (this.offset > 0) {
      q.offset = this.offset;
    }
    if (this.query !== '') {
      q.q = this.query;
    }
    if (this.roles && !this.roles.isEmpty()) {
      q.roles = this.roles.toArray().slice().sort();
    }
    if (this.sort !== '') {
      q.sort = this.sort;
    }
    if (this.lastSeen !== '') {
      q.lastSeen = this.lastSeen;
    }
    if (this.team !== '') {
      q.team = this.team;
    }
    const key = qs.stringify(q, { indices: false, addQueryPrefix: true });
    return key.length > 1 ? key : '?all';
  }

  /**
   * Returns query string for API for member list, an encoded version of this format:
   * "?filter=query:abc,roles:reader|writer&limit=20&offset=40".
   * The API uses the more generic filter param.
   */
  toBackendMembersQueryString() {
    const lastSeenExists = this.lastSeen ? this.lastSeen : '';
    const filter = constructFilterParam(this.roles, this.query, lastSeenExists, this.team, this.accessCheck);
    const q: Partial<MemberFiltersProps> & { filter?: string; expand?: string } = {};
    if (filter !== '') {
      q.filter = filter;
    }
    if (this.limit > 0) {
      q.limit = this.limit;
    }
    if (this.offset > 0) {
      q.offset = this.offset;
    }
    if (this.sort !== '') {
      q.sort = this.sort;
    }
    let expand = 'totalCountWithoutSelfOrOwner';
    if (isCustomRolesEnabled() && isExpandMembersEndpointEnabled()) {
      expand += ',customRoles';
    }
    q.expand = expand;
    return qs.stringify(q, { indices: false, addQueryPrefix: true });
  }

  withEmptyRoles() {
    // whenever the role changes, go back to first page
    return this.set('offset', 0).set('roles', List());
  }

  withAddedRole(role: string) {
    let newRoles;
    if (!this.roles) {
      newRoles = List.of(role);
    } else {
      newRoles = this.roles.push(role);
    }
    return this.set('offset', 0).set('roles', newRoles);
  }

  withRemovedRole(role: string) {
    return this.set('offset', 0).set(
      'roles',
      this.roles.filter((r) => r !== role),
    );
  }

  withQuery(query: string) {
    return this.set('offset', 0).set('query', query);
  }

  withSort(sort: string) {
    return this.set('offset', 0).set('sort', sort);
  }

  withLastSeen(lastSeen: string) {
    return this.set('offset', 0).set('lastSeen', lastSeen);
  }

  withoutPaginationQueryParams() {
    return this.set('limit', -1).set('offset', 0);
  }

  withTeam(team: string) {
    return this.set('offset', 0).set('team', team);
  }
}

export function createMemberFiltersFromQueryMap(queryMap?: queryMapProps) {
  const limit = queryMap ? queryMap.limit : '';
  const offset = queryMap ? queryMap.offset : '';
  const query = queryMap ? queryMap.q : '';
  const roles = queryMap ? queryMap.roles : List();
  const sort = queryMap ? queryMap.sort : 'displayName';
  const lastSeen = queryMap ? queryMap.lastSeen : '';
  const team = queryMap ? queryMap.team : '';
  let rolesArray;
  if (List.isList(roles)) {
    rolesArray = roles.toArray();
  } else if (Array.isArray(roles)) {
    rolesArray = roles;
  } else {
    rolesArray = roles ? [roles] : [];
  }
  const props = {
    limit: parseInt(limit as string, 10) || getMemberListPageSize(),
    offset: parseInt(offset as string, 10) || 0,
    query: query || '',
    roles: List(rolesArray),
    sort,
    lastSeen,
    team,
  };
  return createMemberFilters(props);
}

export function createMemberFilters(props: Partial<MemberFiltersProps>) {
  return new MemberFilters(fromJS(props));
}

export function createDefaultMemberFilters() {
  return createMemberFiltersFromQueryMap({});
}

export function constructFilterParam(roles = List(), query = '', lastSeen = '', team = '', accessCheck = '') {
  const filterParts = [];
  if (query && query !== '') {
    filterParts.push(`query:${encodeURIComponent(query)}`);
  }
  const roleString = roles ? roles.join('|') : '';
  if (roleString !== '') {
    filterParts.push(`role:${roleString}`);
  }
  if (team && team !== '' && team !== 'noteam:true') {
    filterParts.push(`team:${team}`);
  } else if (team === 'noteam:true') {
    filterParts.push(`${team}`);
  }
  if (accessCheck && accessCheck !== '') {
    filterParts.push(`accessCheck:${accessCheck}`);
  }
  if (lastSeen) {
    let lastSeenParam = '';
    switch (lastSeen) {
      case 'never':
        lastSeenParam = 'lastSeen:{"never":true}';
        break;
      case 'no-data':
        lastSeenParam = 'lastSeen:{"noData":true}';
        break;
      default:
        lastSeenParam = `lastSeen:{"before":${convertLastSeenDateToUnix(lastSeen)}}`;
        break;
    }
    filterParts.push(lastSeenParam);
  }
  return filterParts.join(',');
}

export function parseFilterParam(filterParam = '') {
  const filterParts = filterParam.split(',');
  let roles = List();
  let query = '';
  let team = '';
  filterParts.forEach((filterPart) => {
    const keyValue = filterPart.split(':');
    if (keyValue.length === 2) {
      if (keyValue[0] === 'role') {
        const roleParts = keyValue[1].split('|');
        roleParts.forEach((rolePart) => {
          roles = roles.push(rolePart);
        });
      } else if (keyValue[0] === 'query') {
        query = keyValue[1];
      } else if (keyValue[0] === 'team') {
        team = keyValue[1];
      }
    }
  });
  return {
    roles,
    query,
    team,
  };
}

export function createMemberFiltersFromBackendQueryString(queryString: string) {
  const backendParams = qs.parse(queryString, { ignoreQueryPrefix: true });
  const filter = parseFilterParam(backendParams.filter);
  const props = {
    roles: filter.roles,
    query: filter.query,
    limit: backendParams.limit,
    offset: backendParams.offset,
    sort: backendParams.sort,
    team: filter.team,
  };
  return createMemberFilters(props);
}

export function convertLastSeenDateToUnix(lastSeenDate: string) {
  switch (lastSeenDate) {
    case '30-days-ago':
      return subMonths(new Date(), 1).getTime();
    case '60-days-ago':
      return subMonths(new Date(), 2).getTime();
    case '90-days-ago':
      return subMonths(new Date(), 3).getTime();
    case '120-days-ago':
      return subMonths(new Date(), 4).getTime();
    case '180-days-ago':
      return subMonths(new Date(), 6).getTime();
    case '365-days-ago':
      return subMonths(new Date(), 12).getTime();
    default:
      throw new Error(`Invalid last seen date: ${lastSeenDate}`);
  }
}

// We need a notification message when the bulk update members roles flow is complete.
// It's a little tricky to get the right message (based on the various inputs)
// so we have a helper utility to generate it.
export const bulkReplaceMembersRolesSuccessOrErrorMessage = ({
  memberCount,
  customRoleCount,
  role,
  warningsCount,
  errorsCount,
}: BulkMemberActionNotification & { customRoleCount: number; role: RoleName }) => {
  const memberCountText = pluralize('member', memberCount, true);

  const customRoleCountText = pluralize('custom role', customRoleCount, true);
  const roleNameText = customRoleCount ? customRoleCountText : `the ${formattedRoleName(role)} role`;

  let successMessage;
  let errorsMessage;

  if (memberCount) {
    const successText = `${memberCountText} ${wasOrWere(memberCount)} updated to ${roleNameText}.`;

    if (!warningsCount) {
      // All succeed
      successMessage = successText;
    } else {
      // Some succeed, some fail because they already exist on that team
      const warningsCountText = pluralize('member', warningsCount, true);
      const warningsCustomRoleCountText = customRoleCount > 1 ? 'these custom roles' : 'this custom role';
      const warningsRoleNameText = customRoleCount > 0 ? warningsCustomRoleCountText : roleNameText;
      const warningsText = `${warningsCountText} ${wasOrWere(warningsCount)} already assigned ${warningsRoleNameText}.`;
      successMessage = `${successText} ${warningsText}`;
    }
  }

  if (errorsCount) {
    // Some fail outright
    const errorsRoleCountText = customRoleCount > 1 || errorsCount > 1 ? 'roles were' : 'role was';
    errorsMessage = `${errorsCount} member${errorsCount > 1 ? 's’' : '’s'} ${errorsRoleCountText} not updated.`;
  }

  return { successMessage, errorsMessage };
};

export const sortMemberPermissions = (a?: Role | MemberTeams, b?: Role | MemberTeams) =>
  a && b && a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;

const trackEditMember = createTrackerForCategory('EditMember');

// Teams GA tracking
export const trackDeleteMemberButtonClicked = () => trackEditMember('Delete Member Button Clicked');
export const trackMemberNameLinkClicked = () => trackEditMember('Member Name Link Clicked');
export const trackEditMemberRoleButtonClicked = () => trackEditMember('Edit Member Role Button Clicked');
export const trackSaveMemberRoleButtonClicked = (selectedRole: string) =>
  trackEditMember('Save Member Role Button Clicked', { selectedRole });
export const trackAddMemberToTeamsButtonClicked = () => trackEditMember('Add Member To Teams Button Clicked');
export const trackSaveMemberToTeamsButtonClicked = () => trackEditMember('Save Member To Teams Button Clicked');
export const trackMembersHistoryTabClicked = () => trackEditMember('Members History Tab Clicked');
export const trackMembersHistoryTabSearchBarEdited = () => trackEditMember('Members History Search Bar Field Edited');
export const trackMembersHistoryTabDateFilterApplyButtonClicked = () =>
  trackEditMember('Members History Tab Date Filter Apply Button Clicked');
export const trackBulkAddMembersToTeamsButtonClicked = (teamCount: number) =>
  trackEditMember('Bulk Add Members To Teams Button Clicked', { teamCount });
export const trackBulkEditMemberRolesButtonClicked = (roleCount: number) =>
  trackEditMember('Bulk Edit Member Roles Button Clicked', { roleCount });
export const trackBulkEditDropdownClicked = () => trackEditMember('Bulk Edit Dropdown Clicked');
export const trackPrimaryCheckboxButtonClicked = () => trackEditMember('Primary Checkbox Button Clicked');

const trackApproveMember = createTrackerForCategory('ApproveMember');
export const trackApproveRequestClicked = (analyticsAttributes: AnalyticAttributes) =>
  trackApproveMember('Approve Member Clicked', analyticsAttributes);
export const trackPlanUpgradeModalShown = (analyticsAttributes: AnalyticAttributes) =>
  trackApproveMember('Plan Upgrade Modal Shown', analyticsAttributes);
