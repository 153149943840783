import { type GetMembersQueryParams, schemas } from '@gonfalon/openapi';
import { z } from 'zod';

export type UIMembersFilter = {
  ids?: string[];
  query?: string;
  role?: string[];
  team?: string;
  noteam?: boolean;
  lastSeen?:
    | 'never'
    | 'no-data'
    | '30-days-ago'
    | '60-days-ago'
    | '90-days-ago'
    | '120-days-ago'
    | '180-days-ago'
    | '365-days-ago';
  accessCheck?: `${schemas['ActionSpecifier']}:${string}`;
};

export type UIMembersListView = Omit<GetMembersQueryParams, 'filter'> & {
  filter?: UIMembersFilter;
};

export const lastSeenSchema = z.enum([
  'never',
  'no-data',
  '30-days-ago',
  '60-days-ago',
  '90-days-ago',
  '120-days-ago',
  '180-days-ago',
  '365-days-ago',
]);
export type LastSeen = z.infer<typeof lastSeenSchema>;

export const sortOrderingSchema = z.enum(['displayName', '-displayName', 'lastSeen', '-lastSeen']);
export type SortOrdering = z.infer<typeof sortOrderingSchema>;

export const expandSchema = z.enum(['totalCountWithoutSelfOrOwner']);
export type Expand = z.infer<typeof expandSchema>;
