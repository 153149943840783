import { typedObjectKeys } from '@gonfalon/types';

import { isDefaultMembersFilterValue } from './isDefaultMembersFilterValue';
import { UIMembersFilter } from './types';

export function clearDefaultMembersFilterValues(ui: UIMembersFilter) {
  const newFilter = structuredClone(ui);

  for (const key of typedObjectKeys(newFilter)) {
    if (isDefaultMembersFilterValue(key, newFilter[key])) {
      delete newFilter[key];
    }
  }

  return newFilter;
}
