import { parseNumber } from '@gonfalon/filters';

import { parseSort } from './parseSort';
import { parseUIMembersListFilterFromBrowser } from './parseUIMembersListFilterFromBrowser';
import { UIMembersListView } from './types';

export function parseUIMembersListViewFromBrowser(searchParams: URLSearchParams) {
  const ui: UIMembersListView = {};
  ui.offset = parseNumber(searchParams.get('offset'));
  ui.sort = parseSort(searchParams.get('sort'));
  ui.filter = parseUIMembersListFilterFromBrowser(searchParams);
  return ui;
}
