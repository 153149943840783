import { isEqual } from '@gonfalon/es6-utils';

import { defaultMembersFilter } from './defaultMembersFilter';
import type { UIMembersFilter } from './types';

export function isDefaultMembersFilterValue<K extends keyof UIMembersFilter>(name: K, value: UIMembersFilter[K]) {
  if (value === undefined) {
    return false;
  }

  return isEqual(value, defaultMembersFilter[name]);
}
