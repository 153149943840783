import { and, anyOf, contains, equals, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { GetMetricsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetMetricsQueryParams = Omit<GetMetricsOpenAPIQueryParams, 'expand' | 'sort' | 'filter'> & {
  expand?: Array<'experiments' | 'experimentCount' | 'guardedRolloutCount' | 'metricGroupCount'>;
  query?: string;
  sort?: string;
  filter?: {
    query?: string;
    eventKind?: string;
    isNumeric?: boolean;
    unitAggregationType?: 'sum' | 'average';
    tags?: string[];
    maintainerIds?: string[];
    maintainerTeamKey?: string;
    hasConnections?: string | boolean | undefined | null;
  };
};

export function querySerializer(queryParams: GetMetricsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  if (queryParams.filter) {
    const serializedFilter = serializeMetricFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.query) {
    searchParams.set('q', queryParams.query);
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  return searchParams.toString();
}

export function serializeMetricFilterParam(value: NonNullable<GetMetricsQueryParams['filter']>) {
  const filter: QueryFilter[] = [];

  if (value.query) {
    filter.push(equals('query', value.query));
  }

  if (value.eventKind) {
    filter.push(equals('eventKind', value.eventKind));
  }

  if (value.isNumeric !== undefined) {
    filter.push(equals('isNumeric', value.isNumeric));
  }

  if (value.unitAggregationType) {
    filter.push(equals('unitAggregationType', value.unitAggregationType));
  }

  if (value.tags) {
    filter.push(contains('tags', value.tags));
  }

  if (value.maintainerIds) {
    filter.push(anyOf('maintainerIds', value.maintainerIds));
  }

  if (value.maintainerTeamKey) {
    filter.push(equals('maintainerTeamKey', value.maintainerTeamKey));
  }

  if (value.hasConnections !== undefined) {
    filter.push(equals('hasConnections', value.hasConnections));
  }

  return queryfilterToString(and(filter));
}
