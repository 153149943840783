import { mergeSearchParams } from '@gonfalon/router';

import { serializeUIMembersFilterForBrowser } from './serializeUIMembersFilterForBrowser';
import { UIMembersListView } from './types';

export function serializeUIMembersListViewForBrowser(ui: UIMembersListView) {
  let searchParams = new URLSearchParams();

  if ((ui.offset ?? 0) > 0) {
    searchParams.set('offset', String(ui.offset));
  }

  if (ui.sort) {
    searchParams.set('sort', ui.sort);
  }

  const filter = ui.filter;
  if (filter !== undefined) {
    const filterParams = serializeUIMembersFilterForBrowser(filter);
    searchParams = mergeSearchParams(searchParams, filterParams);
  }

  return searchParams;
}
