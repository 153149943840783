import { parseValidParams } from '@gonfalon/filters';

import { defaultMembersFilter } from './defaultMembersFilter';
import { parseLastSeen } from './parseLastSeen';
import { UIMembersFilter } from './types';

export function parseUIMembersListFilterFromBrowser(searchParams: URLSearchParams) {
  const filters: UIMembersFilter = structuredClone(defaultMembersFilter);

  const validParams = parseValidParams(searchParams);

  if (validParams.has('q')) {
    filters.query = validParams.get('q') ?? undefined;
  }

  if (validParams.has('roles')) {
    filters.role = validParams.getAll('roles').filter((r) => r !== '');
  }

  if (validParams.has('team')) {
    filters.team = validParams.get('team') ?? undefined;
  }

  if (validParams.has('lastSeen')) {
    filters.lastSeen = parseLastSeen(validParams.get('lastSeen'));
  }

  return filters;
}
