import { clearDefaultMembersFilterValues } from './clearDefaultMembersFilterValues';
import { UIMembersFilter } from './types';

export function serializeUIMembersFilterForBrowser(ui: UIMembersFilter) {
  const searchParams = new URLSearchParams();

  const filter = clearDefaultMembersFilterValues(ui);

  if (filter !== undefined) {
    if (filter.query) {
      searchParams.set('q', filter.query);
    }

    if (filter.role) {
      for (const role of filter.role) {
        searchParams.append('roles', role);
      }
    }

    if (filter.team) {
      searchParams.set('team', filter.team);
    }

    if (filter.lastSeen) {
      searchParams.set('lastSeen', filter.lastSeen);
    }
  }

  return searchParams;
}
