import { subMonths } from 'date-fns';

import { components } from '../generated/openapi';
import { GetMembersOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetMembersQueryParams = Omit<GetMembersOpenAPIQueryParams, 'filter' | 'expand' | 'sort'> & {
  expand?: Array<'totalCountWithoutSelfOrOwner'>;
  sort?: 'displayName' | '-displayName' | 'lastSeen' | '-lastSeen';
  filter?: {
    ids?: string[];
    query?: string;
    role?: string[];
    team?: string;
    noteam?: boolean;
    lastSeen?:
      | 'never'
      | 'no-data'
      | '30-days-ago'
      | '60-days-ago'
      | '90-days-ago'
      | '120-days-ago'
      | '180-days-ago'
      | '365-days-ago';
    accessCheck?: `${components['schemas']['ActionSpecifier']}:${string}`;
  };
};

export function querySerializer(queryParams: GetMembersQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.limit !== undefined && queryParams.limit > 0) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset !== undefined && queryParams.offset > 0) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  if (queryParams.filter) {
    const serializedFilter = serializeMemberListFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  if (queryParams.expand && queryParams.expand.length > 0) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}

function serializeMemberListFilterParam(value: NonNullable<GetMembersQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (value.role) {
    const unique = Array.from(new Set(value.role));
    if (unique.length > 0) {
      filter.push(`role:${unique.join('|')}`);
    }
  }

  if (value.team) {
    filter.push(`team:${value.team}`);
  }

  if (typeof value.noteam === 'boolean') {
    filter.push(`noteam:${value.noteam}`);
  }

  if (value.lastSeen) {
    switch (value.lastSeen) {
      case 'never':
        filter.push('lastSeen:{"never":true}');
        break;
      case 'no-data':
        filter.push('lastSeen:{"noData":true}');
        break;
      default:
        filter.push(`lastSeen:{"before":${lastSeenDateToUnix(value.lastSeen)}}`);
    }
  }

  if (value?.ids?.length) {
    filter.push(`id:${value.ids.join('|')}`);
  }

  if (value.accessCheck) {
    filter.push(`accessCheck:${value.accessCheck}`);
  }

  return filter.join(',');
}

function lastSeenDateToUnix(lastSeenDate: string) {
  switch (lastSeenDate) {
    case '30-days-ago':
      return subMonths(new Date(), 1).getTime();
    case '60-days-ago':
      return subMonths(new Date(), 2).getTime();
    case '90-days-ago':
      return subMonths(new Date(), 3).getTime();
    case '120-days-ago':
      return subMonths(new Date(), 4).getTime();
    case '180-days-ago':
      return subMonths(new Date(), 6).getTime();
    case '365-days-ago':
      return subMonths(new Date(), 12).getTime();
    default:
      throw new Error(`Invalid last seen date: ${lastSeenDate}`);
  }
}
